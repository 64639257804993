<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="600" persistent>
      <v-card>
        <div class="modal-title">
          <div class="modal-title-color">{{ formTitle }}</div>
          <v-btn depressed color="#1565c0" height="50px" width="40px" @click="close">
            <v-icon color="#64b5f6">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-switch
            color="green"
            class="d-inline-block"
            v-model="card.status"
            :label="cardStatus"
          ></v-switch>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="card.name"
              label="Name"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-select
              :items="cardTypes"
              item-text="name"
              item-value="id"
              v-model="card.cardType"
              label="Card Strategy"
              required
              >
            </v-select>
            <v-autocomplete
              :items="tenants.results"
              :search-input.sync="searchInput"
              item-text="name"
              item-value="uuid"
              return-object
              label="Platform Account"
              v-model="selectedTenant"
              required
              :rules="[rules.required]"
            >
            <template v-slot:selection="data">
                {{ data.item.name }} | {{ data.item.dataCenter }}
              </template>
              <template v-slot:item="data">
                {{ data.item.name }} | {{ data.item.dataCenter }}
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="card.validationPattern"
              label="Validation Pattern"
            ></v-text-field>
            <div class="checkbox-label">Tasks</div>
            <div v-for="task in allTasks" :key="task.id" class="d-inline-block pr-2">
              <v-checkbox :label="task.name"
               :value="task.textId" multiple v-model="card.tasks">
              </v-checkbox>
            </div>
            <v-select
              label="Channel"
              :items="channels"
              item-text="channel"
              item-value="channel"
              v-model="card.channel"
              required
              :rules="[rules.required]"
            >
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="btn-action white--text" @click="close">Cancel</v-btn>
          <v-btn class="btn-action white--text" @click="save">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';
import SevenChannel from '../utility/channels';

export default {
  name: 'CreateCard',
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required field',
    },
    showDialog: false,
    card: { status: true },
    selectedTenant: {},
    channels: SevenChannel.channels,
    formTitle: 'Create customer card',
    searchInput: null,
  }),
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.createCard();
      }
    },
    close() {
      this.showDialog = false;
      this.$refs.form.reset();
      this.$router.push({ name: 'home' });
    },
    getCards() {
      this.$store.dispatch('loadCards');
    },
    getTenants(searchTerm) {
      this.$store.dispatch('loadTenants', searchTerm);
    },
    createCard() {
      this.card.tenantUUID = this.selectedTenant.uuid;
      this.card.dc = this.selectedTenant.dataCenter;
      this.$store.dispatch('createCard', this.card)
        .then(() => this.close());
    },
    searchTenants: _debounce(
      function loadTenants(tenantName) {
        this.getTenants(tenantName);
      }, 500,
    ),
  },
  watch: {
    searchInput(val) {
      this.searchTenants(val);
    },
  },
  created() {
    this.$store.dispatch('loadTenants', '');
    this.showDialog = true;
  },
  computed: {
    ...mapGetters(['tenants', 'allTasks', 'cardTypes']),
    cardStatus() {
      return this.card.status ? 'Active' : 'Inactive';
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-label {
  color: #1976d2;
}
.modal-title {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  background: #1976d2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-color {
    color: #64b5f6;
  }
}
</style>
